.app {
  & > .sidebar {
    background: linear-gradient(180deg, hsl(var(--tone-secondary-bg-hue), var(--tone-secondary-bg-saturation), var(--tone-secondary-bg-lightness)) 0%, hsl(var(--tone-secondary-bg-hue), var(--tone-secondary-bg-saturation), calc(var(--tone-secondary-bg-lightness) + 4 * clamp(-1%, 50% - var(--tone-secondary-bg-lightness), 1%))) 75%, hsl(var(--tone-secondary-bg-hue), var(--tone-secondary-bg-saturation), calc(var(--tone-secondary-bg-lightness) + 8 * clamp(-1%, 50% - var(--tone-secondary-bg-lightness), 1%))) 100%);
    position: relative;

    header {
      justify-content: space-between;
      padding-right: var(--padding);
      padding-left: var(--padding);
      display: flex;

      .logo {
        padding-bottom: var(--padding);
        padding-top: var(--padding);
        align-items: center;
        display: flex;

        svg {
          height: 1.2rem;
        }
      }

      button {
        color: var(--tone-primary-color);
        background: none;
        cursor: pointer;
        border: none;
      }
    }

    nav {
      position: absolute;
      z-index: 100;
      width: 100%;
      display: none;

      &.show-nav {
        display: block;
        background-color: var(--tone-secondary-bg);
      }

      button, a {
        padding: var(--padding);
        padding-left: calc((var(--level, 0) + 1) * var(--padding));
        color: var(--tone-secondary-color);
        border-radius: 0;
        text-align: left;
        background: none;
        cursor: pointer;
        border-right: 0;
        line-height: 1;
        display: block;
        border: none;
        height: auto;
        width: 100%;

        &:hover {
          background-color: hsl(
                          var(--tone-secondary-bg-hue),
                          var(--tone-secondary-bg-saturation),
                          calc(var(--tone-secondary-bg-lightness) + (7 * clamp(-1%, 50% - var(--tone-secondary-bg-lightness), 1%)))
          )
        }

        &.active {
          background-color: hsl(
                          var(--tone-secondary-bg-hue),
                          var(--tone-secondary-bg-saturation),
                          calc(var(--tone-secondary-bg-lightness) + (4 * clamp(-1%, 50% - var(--tone-secondary-bg-lightness), 1%)))
          )
        }
      }

      less-shared-marginal {
        border-top: solid 1px var(--tone-secondary-border);
        background-color: hsl(
                        var(--tone-secondary-bg-hue),
                        var(--tone-secondary-bg-saturation),
                        calc(var(--tone-secondary-bg-lightness) + (9 * clamp(-1%, 50% - var(--tone-secondary-bg-lightness), 1%)))
        );
      }

      select {
        background-color: hsl(
                        var(--tone-secondary-bg-hue),
                        var(--tone-secondary-bg-saturation),
                        calc(var(--tone-secondary-bg-lightness) + (9 * clamp(-1%, 50% - var(--tone-secondary-bg-lightness), 1%)))
        );
        border-width: 0;
        border-bottom-width: 1px;
        border-radius: 0;
      }

      .nav-item {
        padding: var(--padding);
        padding-left: calc((var(--level, 0) + 1) * var(--padding));
        display: block;
      }

      .nav-group {
        border-top: solid 1px var(--tone-secondary-border);
      }
    }
  }

  main {
    padding: var(--padding);
  }
}

@media screen and (min-width: 60rem) {
  .app {
    padding-right: var(--padding);
    padding-left: var(--padding);
    margin: 0 auto;
    min-height: 100vh;
    max-width: 100rem;

    display: grid;
    grid-template-columns: 15rem 1fr;
    grid-template-areas: "sidebar main";

    .toggle-menu {
      display: none;
    }

    & > .sidebar {
      border-right: solid 1px var(--tone-secondary-border);
      border-left: solid 1px var(--tone-secondary-border);

      header {
        grid-area: sidebar;
        justify-content: center;
      }

      nav {
        display: block;
      }
    }

    main {
      grid-area: main;
    }
  }
}


